<template>

  <div style="height: 100vh">
    <!--    Production url: https://v2.bustapesa.com-->
    <!--    Dev url: http://v2.bustapesa.local-->
    <!--    Testing url: http://localhost:4200-->
    <iframe :src="url" style="height: 100%; width:100%; border:0px!important;"  title="Iframe Example"></iframe>
  </div>

</template>

<script>

export default {
  name: 'Bustapesa',
  components: {
  },
  computed: {
    profile: function() {

      return this.getProfile();
    },
    url: function () {

      if(!this.profile) {

        return "https://africrash.Ponyoka.co.ke/";

      }

      return "https://africrash.Ponyoka.co.ke/?token="+this.profile.t1+"&msisdn="+this.getUIValue(this.profile.m)
    }
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","cashafriq");
    var p = this.profile;
    if(!p) {

      //this.setError("Login","Please login to proceed");
      //this.$router.push({ name: 'login', params: { } });
      //return;
    }
  }

}
</script>